import React, { FC } from "react";
import { animated, useSpring, config } from "react-spring";
import { graphql } from "gatsby";

import { useSiteMetadataQuery } from "graphql";

import {
  Box,
  LandingHero,
  LandingHeroProps,
  MDX,
  MDXProps,
  Section,
  SectionProps,
  Text,
} from "components";
import {
  AuthorSection,
  ContactFooter,
  RecommendedNewsSection,
  SEO,
  SEOProps,
} from "containers";
import { DefaultLayout, DefaultLayoutProps } from "layouts";

import { LandingPostTemplateProps } from "./types";

// TODO:
// - add missing frontmatter props to post query
// - update cms config with missing frontmatter props

const LandingPostTemplate: FC<LandingPostTemplateProps> = ({
  data: {
    post: {
      body,
      excerpt,
      frontmatter: {
        author,
        contactFooter,
        description,
        hero,
        image,
        layout,
        main,
        mdx,
        page,
        seo,
        showAuthor = false,
        showFooter = false,
        showHeader = true,
        showRecommended = false,
        subtitle,
        tags,
        title,
      },
    },
  },
  location,
  pageContext: { nextPage, prevPage },
}) => {
  const { siteUrl } = useSiteMetadataQuery();
  const animation = useSpring({
    config: config.slow,
    delay: 250,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  const pageId = page ? `page-${page}` : "page-news-post";

  const seoProps: SEOProps = {
    title: seo?.title || title,
    description: seo?.description
      ? seo?.description
      : description
      ? description
      : excerpt,
    image: location
      ? image
        ? `${location.origin}${image.childImageSharp?.fluid?.src}`
        : `${location.origin}/logo.png`
      : image
        ? `${siteUrl}${image.childImageSharp?.fluid?.src}`
        : `${siteUrl}/logo.png`,
    url: location?.href,
    ...seo,
  };

  const layoutProps: DefaultLayoutProps = {
    on: pageId,
    header: {
      bgColor: "paper",
      color: "paper",
    },
    location,
    themeSwitch: {
      mod: "text-paper hover:text-hover",
    },
    ...layout,
  };

  const heroProps: LandingHeroProps = {
    on: pageId,
    caption: {
      heading: title,
      subheading: subtitle,
      ...hero?.caption,
    },
    media: {
      image: {
        ...image,
        name: title,
      },
      ...hero?.media,
    },
    ...hero,
  };

  const mainProps: SectionProps = {
    as: animated.section,
    on: pageId,
    style: animation,
    pattern: "main",
    modifier: "intro service",
    ...main,
  };

  return (
    <>
      <SEO {...seoProps} />
      <DefaultLayout {...layoutProps}>
        <>
          {showHeader && <LandingHero {...heroProps} />}
          <Section {...mainProps}>
            <MDX {...(mdx as MDXProps)} body={body} />
          </Section>
          {showAuthor && (
            <AuthorSection author={author} on={pageId}>
              {tags?.length && (
                <Box className="flex flex-row flex-wrap mt-4 md:mt-6 xl:mt-8">
                  {tags.map((tag) => (
                    <Text
                      as="span"
                      mod="mb-2 mr-2 md:mb-3 md:mr-3 xl:mb-4 xl:mr-4 py-2 px-4 bg-secondary-light text-secondary-light"
                      pattern="caption"
                      key={tag}
                    >
                      {tag}
                    </Text>
                  ))}
                </Box>
              )}
            </AuthorSection>
          )}
          {showRecommended && (
            <RecommendedNewsSection
              on={pageId}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          )}
          {showFooter && <ContactFooter on={pageId} {...contactFooter} />}
        </>
      </DefaultLayout>
    </>
  );
};

export const landingPostBySlugQuery = graphql`
  query LandingPostBySlug($slug: String!) {
    post: mdx(slug: { regex: $slug }) {
      body
      excerpt
      frontmatter {
        author
        category
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        page
        seo {
          # description
          title
        }
        # subtitle
        tags
        title
      }
      slug
    }
  }
`;

export default LandingPostTemplate;
