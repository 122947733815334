import React, { FC } from "react";
import { find } from "lodash";

import {
  MDX,
  MDXProps,
  Section,
  SectionProps,
  LandingHero,
  LandingHeroProps,
} from "components";
import { ContactFooter, SEO, SEOProps } from "containers";
import { DefaultLayout, DefaultLayoutProps } from "layouts";
import { useMediaQuery, useSiteMetadataQuery } from "graphql";

import { LandingTemplateProps } from "./types";

export const LandingTemplate: FC<LandingTemplateProps> = ({
  children,
  location,
  pageContext: {
    excerpt,
    frontmatter: {
      contactFooter,
      description,
      hero,
      imageName,
      layout,
      main,
      mdx,
      page,
      showFooter = false,
      seo,
      showHeader = true,
      subtitle,
      title,
    },
  },
}) => {
  const { siteUrl } = useSiteMetadataQuery();
  const media = useMediaQuery();
  const image = find(media, ["name", imageName]);
  const pageId = page ? `page-${page}` : "page-landing";

  const seoProps: SEOProps = {
    title: seo?.title || title,
    description: seo?.description
      ? seo?.description
      : description
      ? description
      : excerpt,
    image: location
      ? image
        ? `${location.origin}${image.childImageSharp?.fluid?.src}`
        : `${location.origin}/logo.png`
      : image
        ? `${siteUrl}${image.childImageSharp?.fluid?.src}`
        : `${siteUrl}/logo.png`,
    url: location?.href,
    ...seo,
  };

  const layoutProps: DefaultLayoutProps = {
    on: pageId,
    header: {
      bgColor: "paper",
      color: "paper",
    },
    location,
    themeSwitch: {
      mod: "text-paper hover:text-hover",
    },
    ...layout,
  };

  const heroProps: LandingHeroProps = {
    on: pageId,
    caption: {
      heading: title,
      subheading: subtitle,
      ...hero?.caption,
    },
    media: {
      image: {
        ...image,
        name: title,
      },
      ...hero?.media,
    },
    ...hero,
  };

  const mainProps: SectionProps = {
    on: pageId,
    pattern: "main",
    modifier: "intro service",
    ...main,
  };

  return (
    <>
      <SEO {...seoProps} />
      <DefaultLayout {...layoutProps}>
        <>
          {showHeader && <LandingHero {...heroProps} />}
          {children && (
            <Section {...mainProps}>
              <MDX {...(mdx as MDXProps)}>{children}</MDX>
            </Section>
          )}
          {showFooter && <ContactFooter on={pageId} {...contactFooter} />}
        </>
      </DefaultLayout>
    </>
  );
};

export default LandingTemplate;
