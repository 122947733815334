import React, { FC } from "react";

import {
  DefaultTemplateContext,
  DefaultTemplateProps,
  LandingTemplate,
  LandingTemplateProps,
  PageTemplate,
  PageTemplateProps,
} from "templates";

const safelyGetFrontMatter = (pageContext: DefaultTemplateContext) =>
  pageContext && pageContext.frontmatter ? pageContext.frontmatter : {};

const DefaultTemplate: FC<DefaultTemplateProps> = (props) => {
  const { template } = safelyGetFrontMatter(props.pageContext);

  switch (template) {
    case "landing":
      return <LandingTemplate {...(props as LandingTemplateProps)} />;
    case "page":
    default:
      return <PageTemplate {...(props as PageTemplateProps)} />;
  }
};

export default DefaultTemplate;
