import React, { FC } from "react";

import {
  PageHero,
  PageHeroProps,
  MDX,
  MDXProps,
  Section,
  SectionProps,
} from "components";
import { ContactFooter, SEO, SEOProps } from "containers";
import { DefaultLayout, DefaultLayoutProps } from "layouts";
import { useSiteMetadataQuery } from "graphql";

import { PageTemplateProps } from "./types";

export const PageTemplate: FC<PageTemplateProps> = ({
  children,
  location,
  pageContext: {
    excerpt,
    frontmatter: {
      contactFooter,
      description,
      hero,
      layout,
      main,
      mdx,
      page,
      seo,
      showFooter = false,
      showHeader = true,
      subtitle,
      template,
      title,
    },
  },
}) => {
  const { siteUrl } = useSiteMetadataQuery();
  const pageId = page ? `page-${page}` : "";
  const mainModifier = page === "home" ? "landing intro" : "page intro";
  const mainPattern = !template ? "main" : "content";

  const seoProps: SEOProps = {
    title: seo?.title || title,
    description: seo?.description
      ? seo?.description
      : description
      ? description
      : excerpt,
    image: location ? `${location.origin}/logo.png` : `${siteUrl}/logo.png`,
    url: location?.href,
    ...seo,
  };

  const layoutProps: DefaultLayoutProps = {
    location,
    ...layout,
  };

  const heroProps: PageHeroProps = {
    on: pageId,
    caption: {
      heading: title,
      subheading: subtitle,
    },
    ...hero,
  };

  const mainProps: SectionProps = {
    on: pageId,
    modifier: mainModifier,
    pattern: mainPattern,
    ...main,
  };

  return (
    <>
      <SEO {...seoProps} />
      <DefaultLayout {...layoutProps}>
        <>
          {showHeader && <PageHero {...heroProps} />}
          <Section {...mainProps}>
            <MDX {...(mdx as MDXProps)}>{children}</MDX>
          </Section>
          {showFooter && <ContactFooter on={pageId} {...contactFooter} />}
        </>
      </DefaultLayout>
    </>
  );
};

export default PageTemplate;
